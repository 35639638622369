/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-26",
    versionChannel: "nightly",
    buildDate: "2024-06-26T00:05:22.485Z",
    commitHash: "837c922b55b8b9553d421c8f1834d0b75367c799",
};
